import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getDimensions, eventDimension } from '../../hybrid/wrappers';

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState();

    useEffect(() => {
        function handleResize() {
            const { width } = getDimensions();
            setWindowDimensions(width);
        }
        handleResize();
        eventDimension(handleResize);
        return () => eventDimension(handleResize, false);
    }, []);

    return windowDimensions;
};

export const useWindowDimensionsHeight = () => {
    const [windowDimensions, setWindowDimensions] = useState();

    useEffect(() => {
        function handleResize() {
            const { height } = getDimensions();
            setWindowDimensions(height);
        }
        handleResize();
        eventDimension(handleResize);
        return () => eventDimension(handleResize, false);
    }, []);

    return windowDimensions;
};

export const useWindowScrollDimensions = (func, remove) => {
    !!remove ? window.removeEventListener('scroll', func) : window.addEventListener('scroll', func);

    return () => {
        window.removeEventListener('scroll', func);
    };
};

export const unscrollableBody = (on, turnOffResize) => {
    const router = useRouter()
    const turnOn = () => document.querySelector('html').classList.add('uscrollable');
    const turnOff = () => document.querySelector('html').classList.remove('uscrollable');
    useEffect(() => {
        if (on){
            turnOn()
        } else {
            turnOff()
        }
        return () => {
            turnOff()
        }
    }, [on]);
    useEffect(() => {
        turnOff()
    }, [router]);

    useEffect(() => {
        if (turnOffResize){
            const resetMenuEffect = () => {
                turnOff()
                turnOffResize(false);
            };

            window.addEventListener('resize', resetMenuEffect, false);

            return () => {
                window.removeEventListener('resize', resetMenuEffect, false);
                turnOff()
            };
        }
    }, [turnOffResize]);
    return on;
}

export const useBalancedSize = (proportion, block) => {
    const [size, setSize] = useState(false);

    const windowDimensions = useWindowDimensions(true);

    useEffect(() => {
        if (block.current && proportion) {
            const width = block.current.clientWidth + 1;
            setSize({ width, height: width / proportion });
        }
    }, [proportion, block, windowDimensions]);

    return size;
};